import React from "react";
import {Box, Typography} from "@mui/material";

const Agenda = ({event}) => {
  return(
      <Box style={{minHeight:'15vh',maxHeight:'50vh', overflow:'scroll'}}>
          <Box sx={{m: 1}} style={{overflow: 'scroll', height: '100%'/*, whiteSpace: 'pre-line'*/}}>
              {event.data().contenutoAgenda &&
                  <Typography fontSize={'body1'} color={'rgba(224,81,83,0.85)'}
                              dangerouslySetInnerHTML={{__html: event.data().contenutoAgenda}}>

                  </Typography>
              }
              {/*ag.map(a =>
                  <Box sx={{textAlign: 'left'}} style={{overflow: "visible"}}>
                      <Typography component={'div'} variant={"subtitle1"} fontWeight={"bold"}
                                  textTransform={"uppercase"} color={'primary'}>
                          {a.ora}
                      </Typography>
                      <Typography dangerouslySetInnerHTML={{__html: a.contenuto}}>

                      </Typography>
                  </Box>
              )*/}
          </Box>
      </Box>

  )
}

export default Agenda
