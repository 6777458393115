export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#e05153', '#688675'],
        home_question: ['#e05153', '#688675'],
        applausometro: ['#688675', '#e05153'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#e05153', '#688675'],
        qna: ['#EA4D95', '#E76E51'],
    },
    primary: '#e05153',
    secondary: '#263238',
    accent: '#688675',
    text: '#eceff1',
    background:"#11112C",
    poll:{
        default:"rgba(224,81,83,0.44)",
        answered:"#e05153",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}