import React from "react";
import {Box, Button} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/clap.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import {clap1, clap2} from "../theme/pleshDark";

const emojis = ['☢️','🆘' , '‼️' , '🌈️️️️️️','🌈️️️️️️','🌈️️️️️️','🌈️️️️️️','♥️','♥️','♥️','♥️','♥️','♥️','♥️','♥️','♥️','💦','💦','💦','💦','💦','💦','💦','💦','💦','💦','💦','💦','💦','⚠️' , '🔥' ,'🔥' , '👽' , '🌱', '🌱','👾' , '🤖' ,'👽' , '👾' , '🤖' , '🌱' ,'🌱' , '🪴' , '🌿' ,'🪴' , '🌿' ,'🌿' , '🌵', '🌵', '🌵']

export default function Applausometro({url, idPlaying, eventPlaying, user}) {
    const {id} = useParams()

    async function postDataApplausi(e) {
        emojisplosion({
            emojiCount:10,
            physics: {gravity: 0.18},
            emojis: [emojis[Math.floor(Math.random()*emojis.length)]],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/applausi`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = () => (
        <Button id='clapButton' disabled={!eventPlaying} onClick={postDataApplausi} fullWidth
                variant={"clap"}
            startIcon={
            <img style={{height:'6.625rem'}} src={clap} />}>
        </Button>
    )

    return (
        <Box sx={{position:'absolute', bottom:'16px', my:2, width:'min(35rem, calc(100% - 32px))'}}>
            <ButtonItem/>
        </Box>
    )
}
