import React, {useState} from "react";
import {Box, Button, FormControl, Stack, TextField, Typography, useTheme} from "@mui/material";
import {useParams} from "react-router-dom";
import wordCheck from "../../../functions/wordCheck";
import SuccessCheck from "../../shared/SuccessCheck";
import {useMotionValue, motion} from "framer-motion";

export default function WordcloudQuestion({sendAnswer, success, answered}){
    const [word, setWord] = useState('')
    const [spazio, setSpazio] = useState(false)

    let progress = useMotionValue(90)

    const handleSetWord = (event) => {
        if(event.target.value.length <= 30)
            setWord(event.target.value)
    }

    function capitalizeTheFirstLetterOfEachWord(words) {
        var separateWord = words.toLowerCase().split(' ');
        for (var i = 0; i < separateWord.length; i++) {
            separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                separateWord[i].substring(1);
        }
        return separateWord.join(' ');
    }

    const handleSendAnswer = () => {
        let w = capitalizeTheFirstLetterOfEachWord(word).trim()

        setSpazio(false)
        /*if(w.includes(' ')){
            setSpazio(true)
        } else {*/
            if(wordCheck(w)){
                sendAnswer(w)
                setWord('')
            } else {
                setWord('')
            }
        /*}*/

    }

    return(
        <Stack sx={{position:'relative'}} justifyContent={"start"} alignItems={"start"} style={{textAlign:'center', height:'100%'}} spacing={5}>
            {<Box sx={{width: '100%', mt:3}}>
                <FormControl variant="standard" style={{width: '100%'}}>
                    <TextField
                        inputProps={{style:{fontSize:'0.85rem'}}}
                        placeholder="Scrivici un breve pensiero" id="component-simple" value={word} sx={{fontSize:'3rem'}} onChange={handleSetWord}/>
                </FormControl>
                {/*word && word.includes(' ') &&
                    <Typography sx={{mt: 1}}>Puoi inviare solo una parola</Typography>
                */}
                <Button disabled={word.length<2 || word.length>30 /*|| word.includes(' ')*/} sx={{mt: 4}} variant={'wordcloud'} onClick={handleSendAnswer}>Invia</Button>

                <br/>
                <br/>
                {(success) &&
                    <Box style={{width:'40%'}} margin={'auto'}>
                        <motion.div
                            initial={{ x: 0 }}
                            animate={{ x: 100 }}
                            style={{ x: progress }}
                            transition={{ duration: 0.8 }}
                        />
                        <SuccessCheck progress={progress}/>
                    </Box>
                }
            </Box>}
        </Stack>
    )
}
